import React from "react";
import { Col, Table } from "reactstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import './styles.scss';

export const ComisionsRange = ({ comisionRanges, setComisionRanges, isRemitter }) => {
    const [newCurrency, setNewCurrency] = React.useState({ value: 1, label: '$' });
    const [newAmount, setNewAmount] = React.useState('');
    const [newComision, setNewComision] = React.useState('');

    return (
        <Col md={6}>
            <h5>Franjas de comisión {isRemitter ? 'de remitentes' : ''}</h5>
            <div className="table-responsive">
                <Table className="mb-0">
                    <thead>
                        <tr>
                            <th>Moneda</th>
                            <th>Hasta</th>
                            <th>Comisión</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            comisionRanges.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.currency === 1 ? '$' : 'USD'}</td>
                                    <td>{item.currency === 1 ? '$' : 'USD'}{item.amount}</td>
                                    <td>{item.value}%</td>
                                    <td>
                                        <React.Fragment>
                                            <Link to="#" onClick={() => {
                                                let items = comisionRanges;
                                                items.splice(index, 1);
                                                if (isRemitter) {
                                                    setComisionRanges({ comision_remitter_ranges: items });
                                                    return
                                                }
                                                setComisionRanges({ comision_ranges: items });
                                            }} className="text-danger ms-2">
                                                <i className="mdi mdi-trash-can font-size-18"></i>
                                            </Link>
                                        </React.Fragment>
                                    </td>
                                </tr>
                            ))
                        }

                        {/* Fila para agregar nueva línea */}
                        <tr>
                            <td>
                                <Select
                                    options={[{ value: 1, label: '$' }, { value: 2, label: 'USD' }]}
                                    className="select2 select2-multiple"
                                    value={newCurrency}
                                    onChange={(value) => setNewCurrency(value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Hasta"
                                    value={newAmount}
                                    onChange={(e) => setNewAmount(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Comisión"
                                    value={newComision}
                                    onChange={(e) => setNewComision(e.target.value)}
                                />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => {
                                        if (newAmount === '' || newComision === '') {
                                            alert('Debe ingresar un monto y una comisión');
                                            return;
                                        }
                                        setNewAmount('');
                                        setNewComision('');
                                        if (isRemitter) {
                                            setComisionRanges({
                                                comision_remitter_ranges: [
                                                    ...comisionRanges,
                                                    {
                                                        currency: newCurrency.value,
                                                        amount: newAmount,
                                                        value: newComision,
                                                    }
                                                ]
                                            });
                                            return
                                        }
                                        setComisionRanges({
                                            comision_ranges: [
                                                ...comisionRanges,
                                                {
                                                    currency: newCurrency.value,
                                                    amount: newAmount,
                                                    value: newComision,
                                                }
                                            ]
                                        });
                                    }}
                                >
                                    Agregar
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </Col>
    )
}