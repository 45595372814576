import React, { Component } from 'react';
import './ActualAuctionLogs.scss';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    Col, Row, Card, CardBody, Container, Table,
    Nav, NavItem, NavLink, Input
} from 'reactstrap';
import moment from 'moment';
import { getLogs, postLogsByAuction } from '../../services/AuctionServices';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/actions/UserActions';
import PaginationCustom from '../../components/pagination/Pagination';

class ActualAuctionLogs extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remate actual", link: "#" }
        ],
        auctions: [],
        auctionActive: null,
        loading: false,
        interval: null,
        searchLogs: "",
        pageLogs: 1,
        logs: [],
        pagesLogs: 0
    }

    componentDidMount = async () => {
        this.fetchLogs(true);
    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval);
    }

    fetchLogs = async (showLoading = false) => {
        showLoading && await this.setState({ loading: true })
        try {
            let response = await getLogs({
                token: this.props.sessionProps.account.token,
            });
            let auctionActive = this.state.auctionActive;
            if (auctionActive !== null) {
                response.data.data.map(item => {
                    if (auctionActive.id === item.id) {
                        auctionActive = item;
                    }
                })
            }
            else {
                if (response.data.data.length > 0) {
                    auctionActive = response.data.data[0];
                }
            }
            await this.setState({
                auctions: response.data.data,
                loading: false,
                auctionActive: auctionActive
            });
            this.fetchLogsByAuction(true, true);
            let interval = setInterval(() => {
                this.fetchLogsByAuction();
            }, 15000);
            await this.setState({
                interval: interval
            })
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
        }
    }

    fetchLogsByAuction = async (resetPage = false, showLoading = false) => {
        if (resetPage) {
            await this.setState({ loading: showLoading ? true : false, pageLogs: 1 });
        }
        else {
            await this.setState({ loading: showLoading ? true : false });
        }
        if (!this.state.auctionActive?.id) {
            await this.setState({ loading: false });
            return;
        }
        try {
            let response = await postLogsByAuction({
                token: this.props.sessionProps.account.token,
                auction: this.state.auctionActive?.id,
                idLot: this.state.searchLogs ? this.state.searchLogs : null,
                page: resetPage ? 1 : this.state.pageLogs,
                offset: 100,
            });
            await this.setState({
                logs: response.data.data.logs,
                pagesLogs: response.data.data.pages,
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    toggleTab = async (tab) => {
        if (this.state.auctionActive !== tab) {
            await this.setState({
                auctionActive: tab
            });
        }
    }

    // cuando cambia auctionActive del estado llamo de nuevo
    componentDidUpdate = async (prevProps, prevState) => {
        if (prevState.auctionActive !== this.state.auctionActive) {
            this.fetchLogsByAuction(true, true);
        }
    }

    onPrevLogs = async () => {
        await this.setState({ pageLogs: this.state.pageLogs - 1 });
        this.fetchLogsByAuction();
    }

    onNextLogs = async () => {
        await this.setState({ pageLogs: this.state.pageLogs + 1 });
        this.fetchLogsByAuction();
    }

    onPageLogs = async (pageLogs) => {
        await this.setState({ pageLogs: pageLogs });
        this.fetchLogsByAuction();
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Logs" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="without-padding">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            {
                                                this.state.auctions.map(item =>
                                                    <NavItem>
                                                        <NavLink onClick={() => { this.toggleTab(item); }}
                                                            className={`${this.state.auctionActive?.id === item.id && 'active'} font-weight-bold p-3`}>
                                                            {item.title}
                                                        </NavLink>
                                                    </NavItem>
                                                )
                                            }
                                        </Nav>
                                        <div className="table-responsive">
                                            <input type="text" className="form-control" placeholder="Buscar Lote"
                                                value={this.state.searchLogs} onChange={(e) => this.setState({ searchLogs: e.target.value })}
                                                onBlur={(e) => this.fetchLogsByAuction(true)}
                                                onKeyDown={(e) => { if (e.keyCode === 13) { this.fetchLogsByAuction(true) } }}
                                                style={{ marginBottom: '1rem', width: '250px' }}
                                            />
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Lote</th>
                                                        <th>Nombre</th>
                                                        <th>Cliente</th>
                                                        <th>Oferta</th>
                                                        <th>Fecha</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.logs.map(item =>
                                                            <tr style={{ color: `${item.is_active ? '#505d69' : 'red'}` }}>
                                                                <th scope="row">{item.lot?.id_lot}</th>
                                                                <td>{item.lot?.title}</td>
                                                                <td>{item.user && item.user?.name}</td>
                                                                <td>{item.money} {new Intl.NumberFormat("de-DE").format(item.offer)}</td>
                                                                <td>{`${moment(item.datetime).format('dddd, D. MMMM HH:mm')} hs`}</td>
                                                                <td>{`${item.offerObject !== null && item.is_automatic_offer ? `Automática (máx ${item.money}${item.automaticObject?.amount || '-'})` : ''}`}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                            <PaginationCustom page={this.state.pageLogs} pages={this.state.pagesLogs}
                                                onPrev={this.onPrevLogs} onNext={this.onNextLogs} onPage={this.onPageLogs} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActualAuctionLogs);