import React, { Component } from 'react';
import './ActualAuctionLots.scss';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    Col, Row, Card, CardBody, Container, Table,
    Nav, NavItem, NavLink, Input
} from 'reactstrap';
import moment from 'moment';
import { getLogs, getActualLots } from '../../services/AuctionServices';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/actions/UserActions';
import { Link } from 'react-router-dom';
import { deleteOffer } from '../../services/LotServices';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

class ActualAuctionLots extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remate actual", link: "#" }
        ],
        auctions: [],
        auctionActive: null,
        loading: false,
        moneySymbol: '',
        interval: null
    }

    componentDidMount = async () => {
        toast.configure();
        this.fetchLots(true);
        let interval = setInterval(() => {
            this.fetchLots();
        }, 30000);
        await this.setState({
            interval: interval
        })
    }

    componentWillUnmount = () => {
        clearInterval(this.state.interval);
    }

    fetchLots = async (showLoading = false) => {
        showLoading && await this.setState({ loading: true })
        try {
            let response = await getActualLots({
                token: this.props.sessionProps.account.token,
            });
            let auctionActive = this.state.auctionActive;
            if (auctionActive !== null) {
                response.data.data.map(item => {
                    if (auctionActive.id === item.id) {
                        auctionActive = item;
                    }
                })
            }
            else {
                if (response.data.data.length > 0) {
                    auctionActive = response.data.data[0];
                }
            }
            await this.setState({
                auctions: response.data.data,
                auctionActive: auctionActive,
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
        }
    }

    deleteOffer = (item) => {
        confirmAlert({
            title: `Cancelar oferta`,
            message: `¿Esta seguro que desea cancelar la oferta?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deleteOffer(item)
                    }
                }
            ]
        });
    }

    _deleteOffer = async (item) => {
        await this.setState({ loading: true })
        try {
            let response = await deleteOffer({
                token: this.props.sessionProps.account.token,
                id: item.offerId,
                lot: item.id
            });
            await this.setState({ loading: false });
            this.fetchLots(true);
            toast(`La oferta ha sido cancelada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    toggleTab = async (tab) => {
        if (this.state.auctionActive !== tab) {
            await this.setState({
                auctionActive: tab
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Lotes" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="without-padding">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            {
                                                this.state.auctions.map(item =>
                                                    <NavItem>
                                                        <NavLink onClick={() => { this.toggleTab(item); }}
                                                            className={`${this.state.auctionActive?.id === item.id && 'active'} font-weight-bold p-3`}>
                                                            {item.title}
                                                        </NavLink>
                                                    </NavItem>
                                                )
                                            }
                                        </Nav>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Lote</th>
                                                        <th>Nombre</th>
                                                        <th>Precio base</th>
                                                        <th>Descripción</th>
                                                        <th>Cliente</th>
                                                        <th>Mejor oferta</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.auctionActive?.lots.map(item =>
                                                            <tr>
                                                                <th scope="row">{item.id_lot}</th>
                                                                <td>{item.title}</td>
                                                                <td>{item.price_base}</td>
                                                                <td>{item.description}</td>
                                                                <td>{item.bestUserNameOffer}</td>
                                                                <td>
                                                                    {this.state.auctionActive?.moneySymbol} {item.offer}
                                                                    {
                                                                        item.bestOfferObject && item.bestOfferObject.amount ?
                                                                            ` Aut. hasta (${this.state.auctionActive?.moneySymbol} ${item.bestOfferObject.amount})` : null
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.offerId !== null &&
                                                                        <Link to="#" onClick={() => this.deleteOffer(item)} className="text-danger">Cancelar oferta</Link>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                            <div className="row">
                                                <div className="col-12 text-right" style={{
                                                    display: 'flex', alignItems: 'flex-end',
                                                    justifyContent: 'flex-end', marginTop: '1rem'
                                                }}>
                                                    <h5>Total ingresado:  {` ${this.state.auctionActive?.moneySymbol}`} {this.state.auctionActive?.totalIngress}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActualAuctionLots);