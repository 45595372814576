import React, { Component } from 'react';
import './ModalShowImage.scss';
import { Container, Row, Col, Card, CardBody, FormGroup, Table } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import { Constants } from '../../Constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

class ModalShowImage extends Component {
    state = {
        images: this.props.images
    }

    // estar escuchando si cambia this.props.iamges para actualizar el state
    componentDidUpdate(prevProps) {
        if (this.props.images !== prevProps.images) {
            this.setState({ images: this.props.images });
        }
    }

    close = () => {
        this.props.close();
    }

    onDragEnd = (result, images) => {
        if (!result.destination) return;
        images = Array.from(images);
        const [reorderedImage] = images.splice(result.source.index, 1);
        images.splice(result.destination.index, 0, reorderedImage);
        this.props.onReorder(images); // This function needs to be implemented in parent component
        this.setState({ images });
    }

    render() {
        return (
            <div className="container-modal">
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <ImageGallery items={this.state.images.map(item =>
                                            ({
                                                id: item.id, original: `${item.image.includes(Constants.BASE_URL_IMAGES) ? item.image : Constants.BASE_URL + item.image}`,
                                                thumbnail: `${item.image_thumb.includes(Constants.BASE_URL_IMAGES) ? item.image_thumb : Constants.BASE_URL + item.image_thumb}`
                                            }))}
                                                showPlayButton={false}
                                            />
                                        </div>

                                        <hr />
                                        <h5>Posición imagenes</h5>
                                        <DragDropContext onDragEnd={(result) => this.onDragEnd(result, this.state.images)}>
                                            <Droppable droppableId="gallery-droppable" direction="horizontal">
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {this.state.images.map((item, index) => (
                                                            <Draggable key={item.id} draggableId={`item-${item.id}`} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            margin: '4px 8px',  // Provide horizontal space
                                                                            width: 'calc(10% - 16px)', // Adjust width for spacing
                                                                            position: 'relative',
                                                                            ...provided.draggableProps.style,
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.image_thumb.includes(Constants.BASE_URL_IMAGES) ?
                                                                                <img
                                                                                    src={`${item.image_thumb}`}
                                                                                    alt=""
                                                                                    width="100%"
                                                                                />
                                                                                :
                                                                                <img
                                                                                    src={`${Constants.BASE_URL}${item.image_thumb}`}
                                                                                    alt=""
                                                                                    width="100%"
                                                                                />
                                                                        }
                                                                        {this.props.lotId && (
                                                                            <div className="delete-banner" onClick={() => this.props.deleteImage(item.id)}>
                                                                                <i className="ri-close-circle-line"></i>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>

                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <button onClick={() => this.close()} type="button" className="btn btn-secondary">Cerrar</button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default ModalShowImage;