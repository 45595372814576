import React, { Component } from 'react';
import './AuctionTypes.scss';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import { getItemsType, deleteItemType, addItemType } from '../../services/AuctionServices';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { logoutAction } from '../../redux/actions/UserActions';
import Select from 'react-select';
import { ItemsTable } from './AuctionTypes/ItemsTable';
import { Link } from "react-router-dom";
import { ComisionsRange } from './AuctionTypes/ComisionsRange';

export const COMISION_TYPES = {
    FIXED: 0,
    RANGE: 1
}

class AuctionTypes extends Component {
    state = {
        loading: false,
        breadcrumbItems: [
            { title: "Tipos de remates", link: "/remates" },
            { title: 'Tipos', link: "#" }
        ],
        description: '',
        comision: '',
        items: [],
        id: 0,
        lots_numbers: 4,
        close_each: 2,
        included_comission: { value: 0, label: 'No' },
        extension_alter_next_lot: { value: 0, label: 'No' },
        comision_remitter: '',
        manual_close: { value: 0, label: 'No' },
        manual_open: { value: 0, label: 'No' },
        only_selected_users: { value: 0, label: 'No' },
        type: { value: 0, label: 'Online' },
        comision_type: { value: COMISION_TYPES.FIXED, label: 'Fija' },
        comision_remitter_type: { value: COMISION_TYPES.FIXED, label: 'Fija' },
        comision_ranges: [],
        comision_remitter_ranges: [],
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchItems();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    fetchItems = async () => {
        try {
            let response = await getItemsType({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                items: response.data.data.items,
            })
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    _delete = async (id) => {
        try {
            let response = await deleteItemType({
                token: this.props.sessionProps.account.token,
                id: id
            });
            toast(`Tipo eliminado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchItems();
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    delete = async (id) => {
        confirmAlert({
            title: 'Eliminar tipo',
            message: `¿Esta seguro que desea eliminar el tipo seleccionado?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._delete(id)
                    }
                }
            ]
        });
    }

    save = async () => {
        if (this.state.description === '') {
            toast.error(`Nombre es obligatorio`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        if (this.state.comision_type.value === COMISION_TYPES.FIXED && this.state.comision === '') {
            toast.error(`Comisión es obligatoria`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        if (this.state.comision_remitter_type.value === COMISION_TYPES.FIXED && !this.state.comision_remitter) {
            toast.error(`Comisión remitentes es obligatoria`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        if (!this.state.lots_numbers > 0 || !this.state.close_each > 0) {
            toast.error(`Cantidad de lotes que cierran y los minutos entre cierre y cierre son obligatorios`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        if (this.state.comision_type.value === COMISION_TYPES.RANGE && this.state.comision_ranges.length === 0) {
            toast.error(`Debe ingresar al menos una franja de comisión`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        if (this.state.comision_remitter_type.value === COMISION_TYPES.RANGE && this.state.comision_remitter_ranges.length === 0) {
            toast.error(`Debe ingresar al menos una franja de comisión para remitentes`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        try {
            let response = await addItemType({
                token: this.props.sessionProps.account.token,
                id: this.state.id,
                description: this.state.description,
                comision: this.state.comision || 1,
                comision_remitter: this.state.comision_remitter || 1,
                lots_numbers: this.state.lots_numbers,
                close_each: this.state.close_each,
                included_comission: this.state.included_comission.value,
                extension_alter_next_lot: this.state.extension_alter_next_lot.value,
                manual_close: this.state.manual_close.value,
                manual_open: this.state.manual_open.value,
                only_selected_users: this.state.only_selected_users.value,
                type: this.state.type.value,
                comision_type: this.state.comision_type.value,
                comision_ranges: this.state.comision_ranges,
                comision_remitter_type: this.state.comision_remitter_type.value,
                comision_remitter_ranges: this.state.comision_remitter_ranges
            });
            await this.setState({
                description: '',
                comision: '',
                id: 0,
                lots_numbers: 4,
                close_each: 2,
                included_comission: { value: 0, label: 'No' },
                extension_alter_next_lot: { value: 0, label: 'No' },
                comision_remitter: '',
                manual_close: { value: 0, label: 'No' },
                manual_open: { value: 0, label: 'No' },
                only_selected_users: { value: 0, label: 'No' },
                type: { value: 0, label: 'Online' },
                comision_type: { value: COMISION_TYPES.FIXED, label: 'Fija' },
                comision_remitter_type: { value: COMISION_TYPES.FIXED, label: 'Fija' },
                comision_ranges: [],
                comision_remitter_ranges: [],
            })
            toast(`Tipo de remate guardado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchItems();
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    handleSelectChange = async (selectedOption, name) => {
        await this.setState({
            [name]: selectedOption
        });
    };

    edit = async (item) => {
        await this.setState({
            description: item.description,
            comision: item.comision,
            comision_remitter: item.comision_remitter,
            id: item.id,
            lots_numbers: item.lots_numbers,
            close_each: item.close_each,
            included_comission: item.included_comission ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
            extension_alter_next_lot: item.extension_alter_next_lot ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
            manual_close: item.manual_close ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
            manual_open: item.manual_open ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
            only_selected_users: item.only_selected_users ? { value: 1, label: 'Si' } : { value: 0, label: 'No' },
            type: item.type ? { value: 1, label: 'Presencial' } : { value: 0, label: 'Online' },
            comision_type: item.comision_type ? { value: 1, label: 'Franjas' } : { value: 0, label: 'Fija' },
            comision_remitter_type: item.comision_remitter_type ? { value: 1, label: 'Franjas' } : { value: 0, label: 'Fija' },
            comision_ranges: item.comision_ranges || [],
            comision_remitter_ranges: item.comision_remitter_ranges || []
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Tipos de remates" breadcrumbItems={this.state.breadcrumbItems} />
                        <ItemsTable items={this.state.items} edit={this.edit} deleteItem={this.delete} />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Descripción</Label>
                                                    <Input name="description" className="form-control" type="text" placeholder="Ingrese la descripción"
                                                        value={this.state.description} onChange={this.handleChange} />
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Modalidad</Label>
                                                    <Select
                                                        options={[{ value: 0, label: 'Online' }, { value: 1, label: 'Presencial' }]}
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar modalidad"
                                                        value={this.state.type}
                                                        onChange={(value) => {
                                                            this.setState({ type: value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Cantidad de lotes que cierran</Label>
                                                    <Input name="lots_numbers" className={`form-control ${this.state.manual_close.value ? 'disabled' : ''}`} type="number"
                                                        placeholder="Ingrese la cantidad de lotes que cierran"
                                                        value={this.state.lots_numbers} onChange={this.handleChange}
                                                        disabled={this.state.manual_close.value} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Minutos entre cierre y cierre</Label>
                                                    <Input name="close_each" className={`form-control ${this.state.manual_close.value ? 'disabled' : ''}`} type="number"
                                                        placeholder="Ingrese los minutos entre cierre y cierre"
                                                        value={this.state.close_each} onChange={this.handleChange}
                                                        disabled={this.state.manual_close.value} />
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Tipo Comisión</Label>
                                                    <Select
                                                        options={[{ value: COMISION_TYPES.FIXED, label: 'Fija' }, { value: COMISION_TYPES.RANGE, label: 'Franjas' }]}
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar tipo de comisión"
                                                        value={this.state.comision_type}
                                                        onChange={(value) => {
                                                            this.setState({ comision_type: value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Comisión</Label>
                                                    <Input name="comision" className="form-control"
                                                        type="number" placeholder="Ingrese la comisión" value={this.state.comision} onChange={this.handleChange}
                                                        disabled={this.state.comision_type.value === COMISION_TYPES.RANGE} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Tipo comisión remitentes</Label>
                                                    <Select
                                                        options={[{ value: COMISION_TYPES.FIXED, label: 'Fija' }, { value: COMISION_TYPES.RANGE, label: 'Franjas' }]}
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar tipo de comisión"
                                                        value={this.state.comision_remitter_type}
                                                        onChange={(value) => {
                                                            this.setState({ comision_remitter_type: value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Comisión remitentes</Label>
                                                    <Input name="comision_remitter" className="form-control"
                                                        type="number" placeholder="Ingrese la comisión" value={this.state.comision_remitter} onChange={this.handleChange}
                                                        disabled={this.state.comision_remitter_type.value === COMISION_TYPES.RANGE} />
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Incluye comisión</Label>
                                                    <Select
                                                        options={[{ value: 0, label: 'No' }, { value: 1, label: 'Si' }]}
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar si incluye comisión"
                                                        value={this.state.included_comission}
                                                        onChange={(value) => {
                                                            this.setState({ included_comission: value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Cierre manual</Label>
                                                    <Select
                                                        options={[{ value: 0, label: 'No' }, { value: 1, label: 'Si' }]}
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar si prorroga extienda siguiente cierre"
                                                        value={this.state.manual_close}
                                                        onChange={(value) => {
                                                            this.setState({ manual_close: value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Inicio manual</Label>
                                                    <Select
                                                        options={[{ value: 0, label: 'No' }, { value: 1, label: 'Si' }]}
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar si el inicio es manual"
                                                        value={this.state.manual_open}
                                                        onChange={(value) => {
                                                            this.setState({ manual_open: value });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Prorroga extienda siguiente cierre</Label>
                                                    <Select
                                                        options={[{ value: 0, label: 'No' }, { value: 1, label: 'Si' }]}
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar si prorroga extienda siguiente cierre"
                                                        value={this.state.extension_alter_next_lot}
                                                        onChange={(value) => {
                                                            this.setState({ extension_alter_next_lot: value });
                                                        }}
                                                        isDisabled={this.state.lots_numbers > 1 || this.state.lots_numbers < 1 || this.state.manual_close.value}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Usuarios limitados</Label>
                                                    <Select
                                                        options={[{ value: 0, label: 'No' }, { value: 1, label: 'Si' }]}
                                                        className="select2 select2-multiple"
                                                        placeholder="Seleccionar si limita usuarios"
                                                        value={this.state.only_selected_users}
                                                        onChange={(value) => {
                                                            this.setState({ only_selected_users: value });
                                                        }}
                                                        isDisabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md={9} />

                                            {
                                                this.state.comision_type.value === COMISION_TYPES.RANGE ? (
                                                    <ComisionsRange comisionRanges={this.state.comision_ranges} setComisionRanges={this.setState.bind(this)} />
                                                ) : null
                                            }

                                            {
                                                this.state.comision_remitter_type.value === COMISION_TYPES.RANGE ? (
                                                    <ComisionsRange comisionRanges={this.state.comision_remitter_ranges} setComisionRanges={this.setState.bind(this)} isRemitter />
                                                ) : null
                                            }
                                        </Row>
                                        <FormGroup className="mb-0 text-right">
                                            <div>
                                                <Button onClick={this.save} type="submit" color="primary" className="ml-2">
                                                    Guardar
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionTypes);