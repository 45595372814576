/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import './ModalCreateLot.scss';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/actions/UserActions';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Input } from 'reactstrap';
import axios from 'axios';
import { Constants } from '../../Constants';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Loading from '../Loading';
import { add } from '../../services/LotServices';
import ModalCropImage from '../ModalCropImage/ModalCropImage';
import Select from 'react-select';
import { customersListAutocomplete } from '../../services/UserServices';
import ModalCustomer from '../ModalCustomer/ModalCustomer';
import { addWatermarkAndUpload } from '../Shared/AddWatermark';

class ModalCreateLot extends Component {
    state = {
        loading: false,
        title: '',
        priceBase: '',
        quantity: '',
        description: '',
        remitter: null,
        images: [],
        modalCropImage: [],
        customers: [],
        showModalCustomer: false,
        isForceLot: false,
        forceNumber: '',
        forceNumberLetter: '',
        packing_cost: '',
        location: '',
    }

    componentDidMount = () => {
        toast.configure();
        document.getElementById('title').focus();
        this.fetchCustomers();
    }

    fetchCustomers = async (idUser = null, name = '') => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                customers: response.data.data.customers
            });
            if (idUser !== null) {
                let customer = null;
                for (let _customer of this.state.customers) {
                    if (_customer.id_user === idUser) {
                        customer = _customer;
                    }
                }
                await this.setState({
                    remitter: { value: customer, label: name },
                });
            }
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    close = () => {
        this.props.close();
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        if (name === 'isForceLot') {
            value = event.target.checked
        }
        this.setState({
            [name]: value
        });
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.addLot(false);
            document.getElementById('title').focus();
        }
        if (e.keyCode === 27) {
            this.close();
        }
    }

    closeModalCrop = async (index) => {
        let modalCropImages = this.state.modalCropImage;
        modalCropImages[index].show = false;
        await this.setState({
            modalCropImage: modalCropImages
        });
    }

    handleFileChangeGlobal = async (event) => {
        const userDisableCrop = window.api_name === 'rm'
        if (userDisableCrop) {
            const imagesToState = []
            for await (const file of event.target.files) {
                var form = new FormData();
                form.append('file', file);
                try {
                    const result = await axios.post(`${Constants.BASE_URL_IMAGES}image_upload?type=lots&owner=${Constants.API_NAME}`, form, {
                        headers: {
                            'Authorization': this.props.sessionProps.account.token,
                            'content-type': 'multipart/form-data'
                        }
                    });
                    imagesToState.push({
                        image: `${Constants.BASE_URL_IMAGES}${result.data.data.image}`,
                        image_thumb: `${Constants.BASE_URL_IMAGES}${result.data.data.image_thumb}`,
                        image_name: file.name,
                        image_size: (file.size / 1024).toFixed(2)
                    })
                } catch (error) {
                    if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                        this.props.logout();
                        this.props.history.push('/');
                    }
                    toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        pauseOnHover: false,
                        duration: 10000
                    });
                }
            }
            this.setState({
                images: [...this.state.images, ...imagesToState],
                loading: false
            });
            return;
        }

        let eventTargetFiles = event.target.files;
        await this.setState({ loading: true });
        let files = [];
        for (let file of eventTargetFiles) {
            files.push({
                file: await this.readFile(file),
                show: true
            });
        }
        await this.setState({
            modalCropImage: files,
            loading: false
        })
    }

    readFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    uploadImage = async (file, index) => {
        if (this.props.configAccount?.config?.added_water_mark) {
            addWatermarkAndUpload(file, async (file) => this.uploadImageAction(file, index), this.props)
        }
        else {
            file.name = 'image_' + Date.now() + '.jpeg';
            this.uploadImageAction(file, index);
        }
    }

    uploadImageAction = async (file, index) => {
        var form = new FormData();
        form.append('file', file);
        try {
            const result = await axios.post(`${Constants.BASE_URL_IMAGES}image_upload?type=lots&owner=${Constants.API_NAME}`, form, {
                headers: {
                    'Authorization': this.props.sessionProps.account.token,
                    'content-type': 'multipart/form-data'
                }
            });
            this.closeModalCrop(index);
            this.setState({
                images: [
                    ...this.state.images, {
                        image: `${Constants.BASE_URL_IMAGES}${result.data.data.image}`,
                        image_thumb: `${Constants.BASE_URL_IMAGES}${result.data.data.image_thumb}`,
                        image_name: file.name,
                        image_size: (file.size / 1024).toFixed(2)
                    }
                ],
                loading: false
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    addLot = async (close = true) => {
        await this.setState({ loading: true });
        try {
            let data = {
                token: this.props.sessionProps.account.token,
                lots: [
                    {
                        title: this.state.title,
                        description: this.state.description,
                        price_base: this.state.priceBase !== '' ? this.state.priceBase : 0,
                        quantity: this.state.quantity !== '' ? this.state.quantity : 1,
                        images: this.state.images,
                        remitter: this.state.remitter ? this.state.remitter.value.id_user : null,
                        forceLot: this.state.isForceLot && this.state.forceNumber && this.state.forceNumberLetter ? 1 : 0,
                        idLot: this.state.isForceLot && this.state.forceNumber && !this.state.forceNumberLetter ? this.state.forceNumber : null,
                        forceNumber: this.state.forceNumber,
                        forceNumberLetter: this.state.forceNumberLetter.value,
                        packing_cost: this.state.packing_cost ? this.state.packing_cost : 0,
                        location: this.state.location ? this.state.location : null
                    }
                ],
                auction: this.props.auctionId,
            };
            let response = await add(data);
            await this.setState({
                loading: false,
                title: '',
                description: '',
                priceBase: '',
                quantity: '',
                remitter: null,
                images: [],
                location: '',
            });
            toast(`El lote ha sido creado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
            close && this.close();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                let msg = 'Ocurrió un error, reintentar.';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    closeModal = async (customer) => {
        await this.setState({
            showModalCustomer: false
        });
        this.fetchCustomers(customer.id_user, customer.name);
    }

    deleteImageGlobal = async (image, index) => {
        let images = this.state.images;
        images.splice(index, 1);
        await this.setState({
            images: images
        });
    }

    render() {
        return (
            <div className="container-modal">
                {
                    this.state.showModalCustomer &&
                    <ModalCustomer customerId={this.state.remitter !== null ? this.state.remitter.value.id : 0}
                        {...this.props} close={(customer) => this.closeModal(customer)} />
                }
                {this.state.loading && <Loading />}
                {this.state.modalCropImage.length > 0 &&
                    this.state.modalCropImage.map((item, index) => item.show && <ModalCropImage imageSrc={item}
                        saveImage={(file, index) => this.uploadImage(file, index)} index={index} close={(index) => this.closeModalCrop(index)} />)}
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-10">
                                                <h5 style={{ marginBottom: 0 }}>Crear lote</h5>
                                            </div>
                                            <div className="col-2" onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                                <h5 style={{ marginBottom: 0 }}>X</h5>
                                            </div>
                                        </div>
                                        <hr style={{ marginTop: '0.5rem' }} />
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Título</Label>
                                            <Col md={10}>
                                                <Input name="title" id="title" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                    value={this.state.title} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        {
                                            !window.hide_quantity_lot ? (
                                                <FormGroup row>
                                                    <Label className="col-md-2 col-form-label">Cantidad</Label>
                                                    <Col md={10}>
                                                        <Input id="quantity" name="quantity" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                            value={this.state.quantity} onChange={this.handleChange} />
                                                    </Col>
                                                </FormGroup>

                                            ) : null
                                        }
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Precio base</Label>
                                            <Col md={10}>
                                                <Input name="priceBase" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                    value={this.state.priceBase} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Ubicación</Label>
                                            <Col md={10}>
                                                <Input name="location" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                    value={this.state.location} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        {
                                            this.props.configAccount?.config?.use_cost_packing ?
                                                <FormGroup row>
                                                    <Label className="col-md-2 col-form-label">Costo embalaje</Label>
                                                    <Col md={10}>
                                                        <Input name="packing_cost" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                            value={this.state.packing_cost} onChange={this.handleChange} />
                                                    </Col>
                                                </FormGroup> : null
                                        }
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Descripción</Label>
                                            <Col md={10}>
                                                <Input name="description" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                    value={this.state.description} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Remitente</Label>
                                            <Col md={10} style={{ zIndex: 9, display: 'flex' }}>
                                                <Select
                                                    name="features"
                                                    options={this.state.customers.map(item => ({ value: item, label: item.name }))}
                                                    className="select2 select2-multiple w-100"
                                                    placeholder="Seleccionar remitente a buscar"
                                                    value={this.state.remitter}
                                                    onChange={(e) => this.setState({ remitter: e })}
                                                />
                                                <Link to="#" onClick={() => this.setState({ showModalCustomer: true })} className="text-success">
                                                    <i className="mdi mdi-account-plus font-size-22" style={{ marginLeft: '5px' }}></i>
                                                </Link>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label"># Lote forzado</Label>
                                            <Col md={1} style={{ zIndex: 9, display: 'flex', justifyContent: 'center' }}>
                                                <Input name="isForceLot" type="checkbox" className="form-control"
                                                    value={this.state.isForceLot} onChange={this.handleChange} style={{ width: '20px' }} />
                                            </Col>
                                            <Col md={9} style={{ zIndex: 9, display: 'flex' }}>
                                                {
                                                    this.state.isForceLot ?
                                                        <>
                                                            <Input name="forceNumber" type="text" className="form-control" onKeyDown={(e) => { this.onKeyDown(e) }}
                                                                value={this.state.forceNumber} onChange={this.handleChange} style={{ marginRight: '0.5rem' }}
                                                                placeholder="Ingrese número de lote con formato 001...002" />
                                                            <Select
                                                                name="features"
                                                                options={[
                                                                    { value: 'A', label: 'A' },
                                                                    { value: 'B', label: 'B' },
                                                                    { value: 'C', label: 'C' }
                                                                ]}
                                                                className="select2 select2-multiple w-100"
                                                                placeholder="Seleccione una variación"
                                                                value={this.state.forceNumberLetter}
                                                                onChange={(e) => this.setState({ forceNumberLetter: e })}
                                                            />
                                                        </>
                                                        :
                                                        null
                                                }
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <React.Fragment>
                                                <Dropzone onDrop={acceptedFiles =>
                                                    this.handleFileChangeGlobal({ target: { files: acceptedFiles } })
                                                }>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone" style={{ width: '100%' }}>
                                                            <div
                                                                className="dz-message needsclick mt-2"
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} type="file" name="file" onChange={this.handleFileChangeGlobal} />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                                </div>
                                                                <h4>Soltar archivos o click para subir.</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <div className="dropzone-previews mt-3" id="file-previews" style={{ width: '100%' }}>
                                                    {this.state.images.map((f, i) => {
                                                        return (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                onClick={() => this.setState({
                                                                                    imageShow: [{
                                                                                        image: f.image,
                                                                                        image_thumb: f.image
                                                                                    }]
                                                                                })}
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                src={`${f.image_thumb}`}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {f.image_name}
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                <strong>{f.image_size} KB</strong>
                                                                            </p>
                                                                        </Col>
                                                                        <div className="delete-remision" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => this.deleteImageGlobal(f, i)}>
                                                                            <i class="ri-close-circle-line" style={{ fontSize: '1.5rem' }}></i>
                                                                        </div>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })}
                                                </div>
                                            </React.Fragment>
                                        </FormGroup>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <Button onClick={() => this.close()} type="reset" color="secondary">
                                                    Cerrar
                                                </Button>
                                                <Button onClick={() => this.addLot()} color="primary" className="ml-2">
                                                    Crear
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateLot);