import React from 'react'
import { Card, CardBody, Col, Row, Table } from 'reactstrap'
import { Link } from "react-router-dom";
import './styles.scss'
import { COMISION_TYPES } from '../../AuctionTypes';

export const ItemsTable = ({ items, edit, deleteItem }) => {
    return (
        <Row>
            <Col xs={12}>
                <Card>
                    <CardBody>
                        <div className="table-responsive" style={{ marginTop: '2rem' }}>
                            <Table className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Descripción</th>
                                        <th className='text-center'>Comisión</th>
                                        <th className='text-center'>Incluye comisión</th>
                                        <th className='text-center'>Comisión remitentes</th>
                                        <th className='text-center'>Modalidad</th>
                                        <th className='text-center'>Cierre manual</th>
                                        <th className='text-center'>Inicio manual</th>
                                        <th className='text-center'>Cantidad de lotes que cierran</th>
                                        <th className='text-center'>Minutos entre cierre y cierre</th>
                                        <th className='text-center'>Prorroga extienda siguiente cierre</th>
                                        <th className='text-center'>Usuarios limitados</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        items.map(item =>
                                            <tr>
                                                <td>{item.description}</td>
                                                <td className='text-center'>{item.comision_type === COMISION_TYPES.RANGE ? 'franjas' : `${item.comision || '-'} %`}</td>
                                                <td className='text-center'>{item.included_comission ? 'Si' : 'No'}</td>
                                                <td className='text-center'>{item.comision_remitter_type === COMISION_TYPES.RANGE ? 'franjas' : `${item.comision_remitter || '-'} %`}</td>
                                                <td className='text-center'>{item.type ? 'Presencial' : 'Online'}</td>
                                                <td className='text-center'>{item.manual_close ? 'Si' : 'No'}</td>
                                                <td className='text-center'>{item.manual_open ? 'Si' : 'No'}</td>
                                                <td className='text-center'>{item.manual_close ? '-' : item.lots_numbers}</td>
                                                <td className='text-center'>{item.manual_close ? '-' : item.close_each}</td>
                                                <td className='text-center'>{item.manual_close ? '-' : (item.extension_alter_next_lot ? 'Si' : 'No')}</td>
                                                <td className='text-center'>{item.only_selected_users ? 'Si' : 'No'}</td>
                                                <td>
                                                    <React.Fragment>
                                                        <Link to="#" onClick={() => edit(item)} className="text-primary"><i className="mdi mdi-pencil font-sze-18"></i></Link>
                                                        <Link to="#" onClick={() => deleteItem(item.id)} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                    </React.Fragment>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}