import { Component } from "react"
import { Table } from "reactstrap"
import { Constants } from "../../Constants"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { closeLot, getLots, openLot } from "../../services/LotServices";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Loading from "../Loading";

class BroadcastLots extends Component {
    state = {
        lots: [],
        piesocket: null,
        auctionType: null
    }

    componentDidMount() {
        toast.configure();
        this.fetchLots();
        this.subscribeSocket(this.props.configAccount?.config?.socket_api_key);
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.socket_api_key !== null) {
                this.subscribeSocket(this.props.configAccount?.config?.socket_api_key);
            }
        }

        if ((prevProps.auctionType !== this.props.auctionType)) {
            await this.setState({
                auctionType: this.props.auctionType
            });
        }
    }

    fetchLots = async () => {
        try {
            let data = {
                token: this.props.sessionProps.account.token,
                auction: this.props.auction_id,
            };
            let response = await getLots(data);
            const lots = response.data.data.lots;

            lots.sort((a, b) => {
                if (a.date_close === '2050-01-01 00:00:00' && b.date_close !== '2050-01-01 00:00:00') {
                    return -1;
                }
                if (a.date_close !== '2050-01-01 00:00:00' && b.date_close === '2050-01-01 00:00:00') {
                    return 1;
                }
                return a.id_lot - b.id_lot;
            });

            await this.setState({
                lots,
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    closeLot = (item) => {
        this.closeLotAction(item)
    }

    closeLotAction = async (item) => {
        try {
            await this.setState({ loading: true });
            let response = await closeLot({
                token: this.props.sessionProps.account.token,
                id: item.id
            });
            await this.setState({ loading: false });
            this.fetchLots();
            toast(`Lote cerrado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            } else {
                console.log(error)
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    openLot = async (item) => {
        try {
            await this.setState({ loading: true });
            let response = await openLot({
                token: this.props.sessionProps.account.token,
                id: item.id
            });
            await this.setState({ loading: false });
            this.fetchLots();
            toast(`Lote iniciado con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 5000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            } else {
                console.log(error)
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    subscribeSocket = async (apikey) => {
        if (this.state.piesocket !== null || !apikey) {
            return
        }
        let piesocket = new WebSocket(apikey);
        await this.setState({
            piesocket
        });
        console.log('Connected to the socket server');
        piesocket.onmessage = async (message) => {
            let data = JSON.parse(message.data);
            if (data.socket_io_customer === window.api_name && data.socket_io_topic === 'panel_stream') {
                if (data.id_remate === this.props.auction_id) {
                    let lots = this.state.lots;

                    lots = lots.map(lot => {
                        if (lot.id === data.lot_id) {
                            lot.offersLogs.unshift({
                                name: data.name,
                                offer: data.offer
                            });
                        }
                        return lot;
                    })

                    await this.setState({
                        lots: lots
                    });
                }
            }
        }
    }

    componentWillUnmount = () => {
        this.state.piesocket !== null && this.state.piesocket.close();
        console.log('closed')
    }

    render() {
        return (
            <div className="row">
                {
                    this.state.loading && <Loading />
                }
                <div className="col-8">
                    <div className="table-responsive">
                        <Table className="mb-0 mt-5" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Lote</th>
                                    <th>Nombre</th>
                                    <th>Cantidad</th>
                                    <th>Precio base</th>
                                    <th>Estado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.lots.map(item =>
                                        <tr>
                                            <td>
                                                {
                                                    item.images[0]?.image_thumb.includes(Constants.BASE_URL_IMAGES) ?
                                                        <img onClick={() => this.setState({ imageShow: item.images, imageShowIdItem: item.id })}
                                                            data-dz-thumbnail="" className="avatar-sm rounded bg-light"
                                                            src={`${item.images[0]?.image_thumb}`} style={{ width: '5rem', height: '5rem' }} alt="" />
                                                        :
                                                        <img onClick={() => this.setState({ imageShow: item.images, imageShowIdItem: item.id })}
                                                            data-dz-thumbnail="" className="avatar-sm rounded bg-light"
                                                            src={`${Constants.BASE_URL}${item.images[0]?.image_thumb}`} style={{ width: '5rem', height: '5rem' }} alt="" />

                                                }
                                            </td>
                                            <th scope="row" width="100">
                                                {item.id_lot}
                                            </th>
                                            <td>
                                                {item.title} - {item.description}
                                            </td>
                                            <td>{item.quantity}</td>
                                            <td width="100px">
                                                {item.moneySymbol} {item.price_base}
                                            </td>
                                            <td>
                                                {item.status == Constants.STATUS_INITIAL && <div className="badge badge-soft-warning font-size-12">Pendiente</div>}
                                                {item.status == Constants.STATUS_PENDING && <div className="badge badge-soft-info font-size-12">Subastado</div>}
                                                {item.status == Constants.STATUS_IN_PROGRESS && <div className="badge badge-soft-warning font-size-12">En proceso</div>}
                                                {item.status == Constants.STATUS_NOT_AUCTIONED && <div className="badge badge-soft-secondary font-size-12">No subastado</div>}
                                                {item.status == Constants.STATUS_DELIVERED && <div className="badge badge-soft-success font-size-12">Entregado</div>}
                                            </td>
                                            <td>
                                                {
                                                    this.state.auctionType && this.state.auctionType.manual_open && item.status === Constants.STATUS_INITIAL ? (
                                                        <Link to="#" onClick={() => this.openLot(item)} className="text-info"><i className="mdi mdi-play font-size-18"></i></Link>
                                                    ) : null
                                                }
                                                {
                                                    item.status == Constants.STATUS_IN_PROGRESS && item.date_close === '2050-01-01 00:00:00' ? (
                                                        <Link to="#" onClick={() => this.closeLot(item)} className="text-info"><i className="mdi mdi-pause font-size-18"></i></Link>
                                                    ) : item.bestUserIdOffer ? (
                                                        <label>{item.bestUserNameOffer} ({item.moneySymbol} {item.offer})</label>
                                                    ) : null
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="col-4">
                    <div className="table-responsive">
                        <Table className="mb-0 mt-5" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>Lote</th>
                                    <th>Usuario</th>
                                    <th>Oferta</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.lots.length ?
                                        (this.state.lots[0].offersLogs || []).map(item => (
                                            <tr>
                                                <th scope="row" width="100">
                                                    {this.state.lots[0].id_lot}
                                                </th>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>{item.offer}</td>
                                            </tr>
                                        ))
                                        : null
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

export default connect(mapStateToProps)(BroadcastLots);